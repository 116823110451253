.grad {
    background: url(../assets/Punkte_Hintergrund.png), linear-gradient(295deg, #95b3d6, #abbfd6);
    background-size: cover;
    background-position: 50%;
}

.mission {
    background: url(../assets/Punkte_Hintergrund.png), radial-gradient(circle at center,#0d3b86 0%,#01224b 100%);
    background-size: cover;
    background-position: 50%;
}

.regional {
    background: linear-gradient(89deg, #020042, #00719e);
}

.whiteshad {
    text-shadow: 0 1px 3px rgb(0 0 0 / 30%);
    background-position: 50%;
}

.fifty {
    background-position: 50%;

}

    @media (min-width: 1024px) {
        .communication {
            width: 40% !important;
        }
    }